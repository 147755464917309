<template>
    <modal class="rechargeModel" :isShow="isShow">
        <div class="header">充值列表</div>
        <div class="modal-body clearfix" ref="recharRight">
            <div class="left-box" v-if="MemberRechargePlan">
                <div class="recharge-box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.item-li',marginH:12,paddingH:12,isRow:false}">
                    <div class="item-li" :class="{selected:item.Plan_DetailID==PlanDetail.Plan_DetailID}" v-for="item in MemberRechargePlan.RechargePlanDetail" :key="item" @click="couponClick(item)" >
                        <div v-show="item.Is_Diy_ChargeMoney==false">
                            <div class="name nowrap" v-show="item.Recharge_Kind==0">充{{parseFloat(item.Recharge_Money)}}</div>
                            <div class="name nowrap" v-show="item.Recharge_Kind==1">充{{parseFloat(item.Recharge_Money)}}赠送{{parseFloat(item.Gift_Money)}}元</div>
                            <div class="name nowrap" v-show="item.Recharge_Kind==2">充{{parseFloat(item.Recharge_Money)}}<span v-show="parseFloat(item.Gift_Money)>0">赠送{{parseFloat(item.Gift_Money)}}%</span></div>
                            <div class="describe nowrap">
                                <span v-if="parseFloat(item.Gift_Money)>0">送{{parseFloat(item.Gift_Money)}}{{item.Recharge_Kind==1?'元':'%'}}储值，</span>
                                <span v-if="parseFloat(item.Point_Num)>0">{{parseFloat(item.Point_Num)}}{{item.Point_Kind==1?'':'%'}}个积分</span>
                                <span v-if="item.Number>0">和{{item.Number}}张优惠券！</span>
                            </div>
                            <i class="iconfont icon-gou1"></i>
                        </div>
                        <div v-show="item.Is_Diy_ChargeMoney==true">
                            <div class="name nowrap">自定义充值金额</div>
                            <div class="describe nowrap" >
                                <span>最大不超过{{parseFloat(item.Recharge_Money)}}</span>
                            </div>
                            <i class="iconfont icon-gou1"></i>
                        </div>
                    </div> 
                </div>
                <div class="item-operation">
                    <div class="float-right">
                        <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                        <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                    </div>
                </div>
            </div>
            <div class="right-box" v-if="PlanDetail.Is_Diy_ChargeMoney==false">
                <div class="title">充值详情</div>
                <div class="li" v-show="this.Details.title1">
                    <div class="number">1、</div><div class="describe">{{this.Details.title1}}</div>
                </div>
                <div class="li" v-show="this.Details.title2">
                    <div class="number">2、</div><div class="describe">{{this.Details.title2}}</div>
                </div>
                <div class="li" v-show="this.Details.title3">
                    <div class="number">3、</div><div class="describe">{{this.Details.title3}}</div>
                </div>
            </div>
            <div class="right-box" v-show="PlanDetail.Is_Diy_ChargeMoney==true">
                <div class="title">充值详情</div>
                <div class="diyRecharge" v-if="diyDetails">
                    <div v-for="(item,index) in diyDetails" :key="index" :class="{diyType:index>=1}">
                        <div class="li">
                            <div class="number"></div><div class="describe">{{item.title1}}</div><br/>
                        </div>
                        <div class="li">
                            <div class="number"></div><div class="describe">{{item.title2}}</div><br/>
                        </div>
                        <div class="li">
                            <div class="number"></div><div class="describe">{{item.title3}}</div><br/>
                        </div>
                    </div>
                </div>
                <div class="title" style="padding-top:10px;">自定义充值</div>
                <div class="tr-li">
                    <div class="td-5">
                        <div class="lable-txt">充值金额：</div>
                        <div class="from-input input-icon">
                            <input type="text" ref="recharge" class="key-focus" @focus="tabIndex=1" data-model='rechargeMoney' placeholder="请输入" v-model="rechargeMoney" @keyup.enter="KeyClick(1)"/>
                        </div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="td-5">
                        <div class="lable-txt">赠送金额：</div>
                        <div class="from-input">
                            <input type="text" v-if="isDiyReadonly==false" readonly="readonly" class="key-focus"  placeholder="请输入" v-model="giftMoney"/>
                            <input type="text" ref="gift" v-else class="key-focus" @focus="tabIndex=2" data-model='giftMoney' placeholder="请输入" v-model="giftMoney" @keyup.enter="KeyClick(2)" />
                        </div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="td-5">
                        <div class="lable-txt">赠送积分：</div>
                        <div class="from-input">
                            <input type="text" v-if="isDiyReadonly==false" readonly="readonly" placeholder="请输入" class="key-focus" v-model="pointNum" />
                            <input type="text"  ref="poin" v-else placeholder="请输入" @focus="tabIndex=3" class="key-focus" data-model='pointNum' v-model="pointNum"  @keyup.enter="KeyClick(3)" onkeyup="value=this.value.replace(/\D+/g,'')" />
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1 keyboard">
                    <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                            data-value="9">9</span></div>
                    <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                            data-value="6">6</span></div>
                    <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                            data-value="3">3</span></div>
                    <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                            data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>

import {initKeyBoard} from '/src/common/index.js';

/**充值列表  */
export default {
    name:"rechargeModel",
    emits:["closeModel","rechargeClick"],
    props:{ 
        isShow:Boolean,
        MemberRechargePlan:Object
    },
    data(){
        return{
            textTo:'',
            Details:{
                title1:'',
                title2:'',
                title3:''
            },
            diyDetails:[],
            PlanDetail:'',
            /**充值金额 */
            rechargeMoney:'',
            /**赠送金额 */
            giftMoney:'',
            /**赠送积分 */
            pointNum:'',
            /**自定义最大充值金额 */
            maxRechargeMoney:'',
            /**是否可以自定义赠送金额积分 */
            isDiyReadonly:true,
            tabIndex:0
        }
    },
    mounted(){
        this.$nextTick(()=>{
            initKeyBoard.call(this,this.$refs.recharRight);
            this.$refs.recharge.focus();
            this.init();
            
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        },
        /**赠送储值,积分计算 */
        rechargeMoney(){
            if(parseInt(this.rechargeMoney)> parseInt(this.maxRechargeMoney) && parseInt(this.rechargeMoney)!=0){
                this.$message.warning('请输入正确的金额');
                this.rechargeMoney='',this.giftMoney='',this.pointNum=''
                return 
            }
            if(this.isDiyReadonly==false){
                if(this.PlanDetail.Is_DiyInterval==0){
                    this.giftMoney = Number(this.rechargeMoney * (Number(this.PlanDetail.Gift_Money) / 100)).toFixed(2)
                    this.pointNum = Number(this.rechargeMoney * (Number(this.PlanDetail.Point_Num) / 100)).toFixed(0)
                }else{
                    if(this.PlanDetail.Is_DiyInterval_Range==0){//是否开启自定义金额区间赠送比例浮动范围 0：不开启，默认为0 ; 1：开启
                        this.PlanDetail.RechargeDiyIntervals.map(item=>{
                            if(parseInt(this.rechargeMoney) >=parseInt(item.Begin_Money) && parseInt(this.rechargeMoney)<=parseInt(item.End_Money)){
                                this.giftMoney = Number(this.rechargeMoney * (Number(item.GiftMoney_Percent) / 100)).toFixed(2)
                                this.pointNum = Number(this.rechargeMoney * (Number(item.GiftPoint_Percent) / 100)).toFixed(0)
                            }
                        })
                    }
                }
            }

            //是否为自定义充值金额
            if(this.PlanDetail?.Is_Diy_ChargeMoney){
                //开启了自定义赠送
                if(this.PlanDetail.Is_DiyInterval==1){
                    if(this.PlanDetail?.RechargeDiyIntervals){
                        this.giftMoney=0;
                        this.pointNum=0;
                        this.PlanDetail?.RechargeDiyIntervals.map(re=>{
                            if(Number(this.rechargeMoney)>Number(re.Begin_Money) && Number(this.rechargeMoney)<=Number(re.End_Money)){
                                this.giftMoney = Number(this.rechargeMoney * (Number(re.GiftMoney_Percent) / 100)).toFixed(2)
                                this.pointNum = Number(this.rechargeMoney * (Number(re.GiftPoint_Percent) / 100)).toFixed(0)
                            }
                        })
                    }
                }
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            if(this.MemberRechargePlan){
                if(this.MemberRechargePlan.RechargePlanDetail){
                    this.MemberRechargePlan.RechargePlanDetail.map(re=>{
                        let Numbers=0
                        if(re.RechargeReturnCoupons){
                            re.RechargeReturnCoupons.map(cou=>{
                                Numbers += cou.Number
                            })
                        }
                        re = Object.assign(re, {Number:Numbers})
                    })
                    this.couponClick(this.MemberRechargePlan.RechargePlanDetail[0])
                }
            }
        },
        /**点击充值规则 */
        couponClick(item){
            this.diyDetails=[]
            this.maxRechargeMoney = item.Recharge_Money
            this.PlanDetail = item
            this.Details={title1:'',title2:'',title3:''}
            //是否为自定义充值金额true:是 false:不是
            if(item.Is_Diy_ChargeMoney==true){
                //区间赠送 0关闭 1开启
                if(item.Is_DiyInterval==0){
                    this.isDiyReadonly=false
                    let _diy={
                        title1:'赠送储值:'+ parseFloat(item.Gift_Money)+'%元',
                        title2:'赠送积分:'+ parseFloat(item.Point_Num)+'%个'
                    }
                    this.diyDetails.push(_diy)
                }else{
                    //Is_DiyInterval_Range 0.固定比例 1.范围浮动
                    if(item.Is_DiyInterval_Range==0){
                        this.isDiyReadonly=false
                        item.RechargeDiyIntervals.map(diy=>{
                            let _diy={
                                title1:'充值金额范围:'+ parseFloat(diy.Begin_Money)+'＜～＜＝'+parseFloat(diy.End_Money)+'',
                                title2:'赠送储值:'+ parseFloat(diy.GiftMoney_Percent)+'%元',
                                title3:'赠送积分:'+ parseFloat(diy.GiftPoint_Percent)+'%个'
                            }
                            this.diyDetails.push(_diy)
                        })
                    }else{
                        this.isDiyReadonly = true
                        item.RechargeDiyIntervals.map(diy=>{
                            let unit='元',pointunit='个'
                            //DiyInterval_RangeType 自定义金额区间赠送比例浮动范围类型 0：按百分比浮动 ; 1：按金额浮动
                            if(item.DiyInterval_RangeType==0){
                                unit='%'
                            }
                            if(item.DiyInterval_RangeType==0){
                                pointunit='%'
                            }
                            let _diy={
                                title1:'充值金额范围:'+ parseFloat(diy.Begin_Money)+'＜～＜＝'+parseFloat(diy.End_Money)+'',
                                title2:'赠送储值范围:'+ parseFloat(diy.RangeMoney_Min)+unit+'～'+parseFloat(diy.RangeMoney_Max)+unit+'',
                                title3:'赠送积分范围:'+ parseFloat(diy.RangePoint_Min)+pointunit+'～'+parseFloat(diy.RangePoint_Max)+pointunit+''
                            }
                            if(parseFloat(diy.RangePoint_Min)==0 && parseFloat(diy.RangePoint_Max)==0){
                                _diy.title3=''
                            }
                            this.diyDetails.push(_diy)
                        })
                    }
                }
                //this.Details.title1='充值金额'
            }else{
                this.Details.title1 = '充值'+parseFloat(item.Recharge_Money);
                if(item.Recharge_Kind==1){
                    this.Details.title1+=',赠送'+ parseFloat(item.Gift_Money)+'元;';
                }else if(item.Recharge_Kind==2){
                    this.Details.title1+=',赠送'+ parseFloat(item.Gift_Money)+'%;';
                }
                if(parseFloat(item.Point_Num)>0){
                    this.Details.title2 ='赠送'+parseFloat(item.Point_Num)+(item.Point_Kind==1?'':'%')+'个积分;';
                }
                if(item.RechargeReturnCoupons){
                    let title = ''
                    item.RechargeReturnCoupons.map((re,index)=>{
                        if(index==item.RechargeReturnCoupons.length-1){
                            title+=re.Number+'张'+re.Coupon_Name+'。'
                        }else{
                            title+=re.Number+'张'+re.Coupon_Name+','
                        }
                    })
                    this.Details.title3='赠送'+title
                }
            }
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            /**自定义充值验证 */
            if(this.PlanDetail.Is_Diy_ChargeMoney){
                /**数量最多只允许小数点后4位 */
                let num = /^\d+(\.\d{1,4})?$/;
                if(this.rechargeMoney!=''){
                    if (!num.test(this.rechargeMoney)){
                        this.$message.warning('充值金额最多只允许小数点后4位');
                        return;
                    }
                }else{
                    this.$message.warning('充值金额不能为空');
                    return;
                }
                if(this.giftMoney!=''){
                    if (!num.test(this.giftMoney)){
                        this.$message.warning('赠送金额最多只允许小数点后4位');
                        return;
                    }
                }

                
                if(this.PlanDetail.RechargeDiyIntervals && this.PlanDetail.Is_DiyInterval_Range==1){
                    //是否开启自定义金额区间赠送比例浮动范围 0：不开启，默认为0 ; 1：开启
                    let isError=this.PlanDetail.RechargeDiyIntervals.some(item=>{
                        if(Number(this.rechargeMoney) >=Number(item.Begin_Money) && Number(this.rechargeMoney)<=Number(item.End_Money)){
                            let RangeMoney_Min=Number(item.RangeMoney_Min);
                            let RangeMoney_Max=Number(item.RangeMoney_Max);
                            if(this.PlanDetail.DiyInterval_RangeType==0){//0：按百分比浮动 ; 1：按金额浮动
                                RangeMoney_Min=Number((this.rechargeMoney* (RangeMoney_Min/100)).toFixed(2));
                                RangeMoney_Max=Number((this.rechargeMoneyy* (RangeMoney_Max/100)).toFixed(2));
                            }
                            if(Number(this.giftMoney)< RangeMoney_Min|| Number(this.giftMoney)>RangeMoney_Max){
                                return true;
                            }
                        }
                        return false;
                    })
                    if(isError){
                        this.$message.warning('请输入正确的赠送金额');
                        this.giftMoney=''
                        return
                    }
                    
                    isError=this.PlanDetail.RechargeDiyIntervals.some(item=>{
                        if(Number(this.rechargeMoney) >Number(item.Begin_Money) && Number(this.rechargeMoney)<=Number(item.End_Money)){
                            let RangePoint_Min=Number(item.RangePoint_Min);
                            let RangePoint_Max=Number(item.RangePoint_Max);
                            if(this.PlanDetail.DiyInterval_RangeType==0){//0：按百分比浮动 ; 1：按金额浮动
                                RangePoint_Min=Number((this.rechargeMoney* (RangePoint_Min/100)).toFixed(2));
                                RangePoint_Max=Number((this.rechargeMoney* (RangePoint_Max/100)).toFixed(2));
                            }
                            if(Number(this.pointNum)<RangePoint_Min || Number(this.pointNum)>RangePoint_Max){
                                return true;
                            }
                        }
                        return false;
                    })
                    
                    if(isError){
                        this.$message.warning('请输入正确的赠送积分');
                        this.pointNum=''
                        return
                    }
                }
            }else{
                this.rechargeMoney = this.PlanDetail.Recharge_Money;

                if(this.PlanDetail.Recharge_Kind==2){//按充值金额%赠送
                    this.giftMoney = Number((this.rechargeMoney*parseFloat(this.PlanDetail.Gift_Money/100)).toFixed(2));
                }else{
                    this.giftMoney = parseFloat(this.PlanDetail.Gift_Money);
                }
                
                if(this.PlanDetail.Point_Kind==2){//按充值金额%赠送 个
                    this.pointNum =  Number((this.rechargeMoney*parseFloat(this.PlanDetail.Point_Num/100)).toFixed(0));
                }else{
                    this.pointNum = Number(this.PlanDetail.Point_Num).toFixed(0);
                }
            }   
            this.$emit("rechargeClick",this.PlanDetail,Number(this.rechargeMoney).toFixed(2),Number(this.giftMoney).toFixed(2),Number(this.pointNum).toFixed(0));
        },
        /**input回车 */
        KeyClick(type){
            if(type==1){
                this.$refs.gift?.focus();
            }else if(type==2){
                this.$refs.poin?.focus();
            }else{
                this.confirm();
            }
        },
        next(){
            if(this.tabIndex==1){
                this.$refs.gift?.focus();
            }else if(this.tabIndex==2){
                this.$refs.poin?.focus();
            }else{
                this.confirm();
            }
        },
    }
}
</script>

<style lang="scss">
@import "./rechargeModel.scss";
</style>